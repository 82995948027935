import React from 'react';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';
import Icon from 'common-components/Icon';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import markToDestroy from 'utils/form/mark-to-destroy';
import RenderDateField from 'common-components/form/RenderDateField';
import Label from 'common-components/form/Label';
import { firewallUnsupportedCharacters } from 'utils/form/validations';

const propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  renderField: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
};

function ComplementaryEducations(props) {
  const {
    fields, fieldLabel, fieldValidation, formName, renderField,
    systemFieldsForm: {
      complementary_education: complementaryEducationField,
    },
    intl: { messages },
  } = props;

  if (complementaryEducationField === undefined) return null;

  const placeholders = {
    month: `${messages['jobs.time.month']}`,
    year: `${messages['jobs.time.year']}`,
  };

  return (
    <div className="mt-[64px]">
      <p className="h1">{messages['jobs.complementary_training']}</p>
      {fields.map((field, index) => {
        if (fields.get(index)._destroy) return null;
        const key = field + index;

        return (
          <div key={key} className="block inner-card disabled">
            <div className="row form-group">
              <div className="col-12">
                {
                  renderField(
                    fieldLabel(complementaryEducationField, messages['jobs.training']),
                    `${field}.degree`,
                    fieldValidation(complementaryEducationField, firewallUnsupportedCharacters),
                    `${messages['jobs.training']}`,
                    `${fields.name}[${index}].degree`,
                  )
                }
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12">
                {
                  renderField(
                    fieldLabel(complementaryEducationField, messages['jobs.center']),
                    `${field}.educationCenter`,
                    fieldValidation(complementaryEducationField, firewallUnsupportedCharacters),
                    `${messages['jobs.center']}`,
                    `${fields.name}[${index}].educationCenter`,
                  )
                }
              </div>
            </div>
            <div className="form-group">
              <div className="block-date">
                <div className="date row">
                  <div className="col-6" data-input-name={`${fields.name}[${index}].initDate`} data-label-text={messages['jobs.experience_init_date']}>
                    <Label label={messages['jobs.experience_init_date']} />
                    <Field
                      withoutDay
                      component={RenderDateField}
                      formName={formName}
                      name={`${field}.initDate`}
                      placeholders={placeholders}
                    />
                  </div>
                  <div className="col-6" data-input-name={`${fields.name}[${index}].endDate`} data-label-text={messages['jobs.experience_end_date']}>
                    <Label label={messages['jobs.experience_end_date']} />
                    <Field
                      withoutDay
                      component={RenderDateField}
                      formName={formName}
                      name={`${field}.endDate`}
                      placeholders={placeholders}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button type="button" className="button secondary" onClick={() => markToDestroy(index, fields)}>
              <Icon name="huge-delete-02" />
              {messages['jobs.delete_complementary_training']}
            </button>
          </div>
        );
      })}
      <button type="button" className="button outline" onClick={() => fields.push({})}>
        <Icon name="huge-plus-sign" />
        {messages['jobs.add_complementary_training']}
      </button>
    </div>
  );
}

ComplementaryEducations.propTypes = propTypes;
ComplementaryEducations.defaultProps = defaultProps;

export default injectIntl(ComplementaryEducations);
